//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        classify_id: [],
        name: '',
        status: '',
      },
      allchecked: !1,
      batchActionId: '',
      list: [],
      serviceClassify: [],
      total_number: 0,
    };
  },
  created() {
    this.getList();
    this.$axios
      .post(this.$api.samecity.service.classifyList, {
        is_show: this.is_show,
        name: this.name,
      })
      .then(res => {
        if (res.code == 0) {
          this.serviceClassify = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    //全选
    selectToatl(val) {
      if (val) {
        for (let i in this.list) this.list[i].checked = !0;
      } else {
        for (let i in this.list) this.list[i].checked = !1;
      }
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        classify_id: [],
        name: '',
        status: '',
      };
      this.getList();
    },
    //批量操作
    handleBatchAction() {
      let list = this.list;
      if (this.batchActionId == 1) {
        //批量删除服务
        let id = [];
        for (let i in list) {
          if (list[i].checked) id.push(list[i].id);
        }
        if (!id.length) return this.$message.warning('请选择要删除的服务');
        this.$confirm('请确认是否批量删除所选服务', '批量删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$axios
            .post(this.$api.samecity.service.delService, {
              id: id,
            })
            .then(res => {
              if (res.code == 0) {
                this.getList();
                this.$message.success('删除成功');
              } else {
                this.$message.error(res.msg);
              }
            });
        });
      } else {
        this.$message.warning('请选择批量操作类型');
      }
    },
    getList() {
      let searchForm = Object.assign({}, this.searchForm);
      if (searchForm.classify_id.length) {
        searchForm.classify_id.length > 1 ? (searchForm.classify_id = searchForm.classify_id[1]) : (searchForm.classify_id = searchForm.classify_id[0]);
      }
      this.$axios.post(this.$api.samecity.service.serviceList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            item.checked = !1;
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del(id) {
      this.$confirm('确认删除此服务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.samecity.service.delService, {
            id,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.$message.success('删除成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
